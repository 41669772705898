import {
    HStack, Spacer, Flex, Box, Button, Text,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Center,
    Heading
} from '@chakra-ui/react'
import Link from 'next/link'
import {useRouter} from 'next/router'
import { HamburgerIcon } from '@chakra-ui/icons'
import { signIn, signOut, useSession } from 'next-auth/client'
import { useState, useEffect } from 'react'
import fetcher from '../utils/fetcher'

export function Navigation() {
    const router = useRouter();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const [data, setData] = useState([])
    const [session, loading] = useSession()

    useEffect(() => {
        async function getMyDepartment(userId) {
            const response = await fetcher(`departments?user=${userId}`);
            if (response) {
                console.log(response[0].parts)
                setData(response[0].parts)
            }
        }
        if (session) {
            getMyDepartment(session.id)
        }
    }, [session])

    return (
        <>
            <Box bg="gray.500" w="100%" p={4}  position="fixed" zIndex="999" >
                <Flex h="48px">
                    <Center h="100%" >
                        <HamburgerIcon ref={btnRef} onClick={onOpen} w={9} h={9} color="white" />
                    </Center>
                    
                    <Link href="/" >
                        <a>
                            <Button marginLeft="40px" colorScheme="gray.500" size="med" height="100%" width="auto" >
                                <Heading size="lg"> Next-DPI </Heading> 
                            </Button>
                        </a>
                    </Link>
                    <Spacer padding />
                    <Link href="/about">
                        <a>
                            <Button colorScheme="gray.500" size="med" height="100%" width="auto">
                                <Heading size="lg" > About Us </Heading>
                            </Button>
                        </a>
                    </Link>
                    <Spacer padding />
                    
                    {/* {
                        session?.user?.email ?
                        <Button colorScheme="blue.500" size="med" height="100%" 
                        width="auto" onClick={() => signOut()} > 
                        <Heading size="lg" > Log Out </Heading> </Button> :
                        <Button colorScheme="blue.500" size="med" height="100%" 
                        width="auto" onClick={() => signIn()}><Heading size="lg" > Log In </Heading></Button>
                    
                    } */}
                </Flex>
            </Box>
            <Box h="80px"></Box>
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Next DPI</DrawerHeader>
                        <DrawerBody>
                            {
                                data?.map(function (item) {
                                    return <a key={item.id} style={{cursor: "pointer"}} onClick={()=>{
                                        window.location = `/parts/${item.id}`;
                                    }}>
                                            <Box  padding="10px" color="orange.400" >
                                            {item.Name}
                                            </Box>
                                     
                                    </a>
                                })
                            }
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
            
        </>
    )
}